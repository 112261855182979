
import EventValidationMixin from "~/mixin/event-validation-mixin";
import CalSignature from "./cal-signature.vue";
export default {
  name: "cal-main-slide",
  components: { CalSignature },
  mixins: [EventValidationMixin],
  props: {
    slideType: {
      default: "herobanner",
      type: String,
    },
    slide: {
      default: {},
      type: Object,
    },
    wedding: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    handleClick() {
      if (this.slide.button_path.startsWith("$")) {
        this.emitCustomEvent({ type: this.slide.button_path, data: "" });
      } else if (this.slide.button_path.startsWith("https")) {
        window.open(this.slide.button_path, "_blank");
      } else {
        this.$router.push(`/${this.$i18n.locale}/${this.slide.button_path}`);
      }
    },
    openLink() {
      window.open(this.slide["button_path"], "_blank");
    },
  },
};
