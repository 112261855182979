
import calLocalChange from "~/components/cal-local-change.vue";
import { butter } from "../plugins/buttercms";
import CalAccountsMenu from './registration/cal-accounts-menu.vue';
import CalDropdownSelection from '../components/filter-section/cal-drop-down.vue';

export default {
  name: "cal-nav",
  components: {
    calLocalChange,
    CalAccountsMenu,
    CalDropdownSelection
  },
  head() {
    return {
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Callia Flowers",
            "logo": "https://callia.com/callia-logo.svg",
            "url": "https://callia.com",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-888-618-1211",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/calliaflowers/",
              "https://twitter.com/calliaflowers",
              "https://www.linkedin.com/company/callia-flowers",
              "https://www.instagram.com/calliaflowers/",
              "https://www.tiktok.com/@calliaflowers"
            ],
            "description": "Callia is the best experience sending flowers across Canada in a signature big blue box. Our bouquets are always hand-tied, picture-perfect and last 2+ weeks!."
          })
        }
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  },
  created() {
    this.loadCartItemCount();
    this.$nuxt.$on("cartItemsUpdated", () => {
      this.loadCartItemCount();
    });
  },
  data() {
    return {
      menuMobile: [],
      menuDesktop: [],
      bottomMenuMobile: [],
      showMobileMenu: false,
      catItemsCount: 0,
      schema:{
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Callia Flowers",
        "logo": "https://callia.com/callia-logo.svg",
        "url": "https://callia.com",
        "sameAs": [
          "https://www.facebook.com/calliaflowers/",
          "https://twitter.com/calliaflowers",
          "https://www.linkedin.com/company/callia-flowers",
          "https://www.instagram.com/calliaflowers/",
          "https://www.tiktok.com/@calliaflowers"
        ]
      },
      BreadcrumbList: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": []
      },
      pagedata: {},
    };
  },
  watch: {
    $route() {
      this.showMobileMenu = false;
      this.toggleBodyScrolling();
      this.getNavigation();
    },
  },
  async fetch() {
    await this.getNavigation();
  },
  methods: {
    loadCartItemCount() {
      const existingSessionCartItems = sessionStorage.cartItems
        ? JSON.parse(sessionStorage.cartItems)
        : {};
      if (existingSessionCartItems) {
        this.catItemsCount = Object.keys(existingSessionCartItems).length;
      }
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
      this.toggleBodyScrolling();
    },
    async getNavigation() {
      try {
        const response = await butter(this.$config).content.retrieve(
          ["navigation_menu"],
          {
            locale: this.$i18n.locale,
            timeout: 10000,
          }
        );
        this.pagedata = response.data.data["navigation_menu"][0];
        this.menuMobile = response.data.data["navigation_menu"][0]["menu_mobile"];
        const scrumbList= this.menuMobile.concat(this.pagedata.shop_sub_menu_list);
        this.setUpBreadcrumbList(scrumbList);
        this.menuDesktop =
          this.pagedata["menu_desktop"];
        this.bottomMenuMobile =
          this.pagedata["bottom_menu_mobile"];
      } catch (error) {
        console.error(error);
      }
    },
    toggleBodyScrolling() {
      let body = document.body;
      body.style.overflow = (!this.showMobileMenu) ? 'auto' : 'hidden';
    },
    setUpBreadcrumbList(list){
      this.BreadcrumbList.itemListElement.push({
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://callia.com/en-ca"
        })
       list.forEach((element,index) => {
          this.BreadcrumbList.itemListElement.push({
          "@type": "ListItem",
          "position": index+2,
          "name": element.label,
          "item": `https://callia.com/en-ca${element.url}`
          })
       })
       this.BreadcrumbList.itemListElement.push(
        {
          "@type": "ListItem",
          "position": this.BreadcrumbList.itemListElement.length+1,
          "name": "Sign In",
          "item": "https://callia.com/en-ca/sign-in"
        },
        {
          "@type": "ListItem",
          "position": this.BreadcrumbList.itemListElement.length+2,
          "name": "Cart",
          "item": "https://callia.com/en-ca/cart"
        })
    }

  },
};
