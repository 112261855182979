
import calLocalChange from "~/components/cal-local-change.vue";
import SubscribeMixin from "~/mixin/subscribe-mixin";
import { butter } from "../plugins/buttercms";
export default {
  name: "cal-footer",
  components: { calLocalChange },
  mixins: [SubscribeMixin],
  data() {
    return {
      index: 0,
      footer: [],
      showSuccessMessage: false,
      activeMobFooterSection: -1,
      form: {
        name: "",
        email: "",
      }
    };
  },
  watch: {
    $route() {
      this.showMobileMenu = false;
      this.getFooter();
    },
    "form.name"() {
      this.validateFormDetails();
    },
    "form.email"(value) {
      this.form.email = value;
      this.validateFormDetails(value);
    },
  },
  async fetch() {
    await this.getFooter();
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },

    toggleFooterSection(index) {
      this.activeMobFooterSection === index
        ? (this.activeMobFooterSection = -1)
        : (this.activeMobFooterSection = index);
    },
    async getFooter() {
      try {
        const response = await butter(this.$config).content.retrieve(
          ["navigation_menu"],
          {
            locale: this.$i18n.locale,
            timeout: 10000,
          }
        );
        const { navigation_menu } = response.data.data;
        this.footer = navigation_menu[0];
      } catch (error) {
        console.error(error);
      }
    },
    homePage() {
      if (
        this.$route.path == "/" ||
        this.$route.path == "/en-ca" ||
        this.$route.path == "/en-us" ||
        this.$route.path == "/fr-ca"
      ) {
        return false;
      } else {
        return true;
      }
    },
    validateFormDetails(value) {
      if (
        this.form.name &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
      ) {
        return false;
      } else {
        return true;
      }
    },
    async submit() {
      try {
        if (this.form.name !== '' && this.form.email !== '' && !this.validateFormDetails(this.form.email)) {
          await this.subscribe(
            this.$axios,
            this.form.name,
            this.form.email,
            this.$i18n.locale.split("-")
          );
          this.showSuccessMessage = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
